<template>
  <div class="uk-width-1-1 uk-text-center">
    <div class="uk-width-1-1" v-for="efficacy in Object.keys(typeEfficacies).sort((a, b) => b - a)" v-bind:key="efficacy">
      <p style="margin-bottom: 0; margin-top: 30px;"><b>{{ efficacySummary(efficacy) }} ({{ efficacy }}x damage)</b></p>
      <div class="uk-flex uk-width-1-1 uk-flex-wrap uk-flex-center">
        <div v-for="type in typeEfficacies[efficacy]" v-bind:key="type" class="uk-text-capitalize type-calc__type-efficacy__result">
          <div :style="{'background-color':typeColorResolver(type)}" class="uk-text-center">
            {{ typeNameResolver(type) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PokemonSpecies from '../dataStorage/pokemonSpecies'

export default {
  name: 'AttackEfficacy',
  props: {
    typeEfficacies: Object,
  },
  methods: {
    typeNameResolver: PokemonSpecies.typeNameResolver,
    typeColorResolver: PokemonSpecies.typeColorResolver,
    efficacySummary: function (efficacy) {
      const text = {
        0.25 : 'Resist damage',
        0.5  : 'Not very effective',
        0    : 'Immunity',
        1    : 'Normal',
        2    : 'Super effective',
        4    : 'Doubly effective',
      }

      return text[efficacy]
    },
  },
  computed: {

  },
  watch: {
    selectedType: function (newVal, oldVal) {
      if (newVal == oldVal || newVal == this.activeType)
        return

      this.onChangeType(newVal)
    },
    activeType: function (newVal, oldVal) {
      if (newVal == oldVal)
        return

      this.selectedType = newVal
    },
  },
}
</script>

<style scoped>
.type-calc__type-efficacy__result {
  width: 80px;
}

.type-calc__type-efficacy__result {
  color: #ffffff;
  text-shadow: 0 1px 0 #000,0 0 1px rgba(0,0,0,.6),0 0 2px rgba(0,0,0,.3),0 0 3px rgba(0,0,0,.6),0 0 4px rgba(0,0,0,.3);
  -webkit-font-smoothing: antialiased;
  padding: 5px 10px;
  width: 85px;
  font-weight: bold;
}
</style>
