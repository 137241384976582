<template>
  <div class="uk-margin">
    <label class="uk-form-label" for="form-stacked-text">{{ statsName }}</label>
    <div class="uk-form-controls uk-grid">
      <div style="width: 40px;">
        <b>{{ statsVal }}</b>
      </div>
      <div class="uk-width-expand uk-padding-remove">
        <progress class="uk-progress" :value="statsVal" :max="statsMax"></progress>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PokeAPIListRenderer',
  props: {
    statsName: String,
    statsVal: Number,
    statsMax: Number
  }
}
</script>

<style scoped>

</style>
