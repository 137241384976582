const Pokedex = require("pokeapi-js-wrapper")
const P = new Pokedex.Pokedex()

function getPokemonByDexNum (dexNum) {
  return P.resource(`api/v2/pokemon-species/${dexNum}`)
}

function getVarietyDetail (varieties, index) {
  const urlComponent = varieties[index].pokemon.url.split('/')
  let varietyId = 0

  for (let i = urlComponent.length - 1; i > 0; --i) {
    const componentContent = Number.parseInt(urlComponent[i])
    if (!Number.isNaN(componentContent)) {
      varietyId = componentContent
      break
    }
  }

  return P.resource(`api/v2/pokemon/${varietyId}`)
}

function getAbilityInfo (ability) {
  return P.resource(ability.ability.url)
}

export default {
  getPokemonByDexNum,
  getVarietyDetail,
  getAbilityInfo,
}
