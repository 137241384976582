<template>
  <div class="uk-container dex-entry uk-container-expand" :style="style">
    <div class="uk-container uk-padding">
      <vk-grid>
        <div class="uk-width-1-1 uk-width-1-3@s uk-flex-last@s">
          <div class="dex-entry__summary">
            <div class="uk-hidden@s" style="background-color: rgba(255, 255, 255, 0.9); padding: 5px;">
              <button class="uk-button uk-button-link" @click="$router.go(-1)">&#8592; Back</button>
            </div>
            <vk-card class="dex-entry__info-card" style="background-color: rgba(255, 255, 255, 0.9)">
              <div id="dex-entry__dex-num">#{{ dexNum }}</div>
              <div class="uk-text-center">
                <PokemonSpriteComponent :speciesNumber="dexNum" />
                <vk-card-title class="dex-entry__title">{{ speciesName }}</vk-card-title>
              </div>
              <div class="uk-card uk-card-body">
                <PokemonTypeComponent :pokemonId="dexNum" />

                <p class="uk-text-left"><b>Summary</b><br />{{ speciesName }} is a {{ speciesTypeText }} type {{ specialPokemonText }} Pok&eacute;mon introduced in generation {{ generation }}.</p>
                <p class="uk-text-left">{{ this.evolutionSummary }}</p>
              </div>
            </vk-card>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-2-3@s">
          <vk-card class="dex-entry__dex-detail">
            <div class="uk-visible@s">
              <button class="uk-button uk-button-link" @click="$router.go(-1)">&#8592; Back</button>
              <hr class="uk-divider-icon">
            </div>
            <h1 class="uk-visible@s">{{ speciesName }}</h1>
            <p><router-link
              :to="{ name: 'TypeCalc', params: { tab: 'defense', slug: dexNum }}">Click here to view the type efficacy against {{ speciesName }}.</router-link></p>
            <PokeAPI :rootView="true" :dexNum="dexNum" :speciesName="speciesName" />
          </vk-card>
        </div>
      </vk-grid>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PokemonColorNames from '../dataStorage/pokemonColor'
import PokemonSpecies from '../dataStorage/pokemonSpecies'
import PokemonSpriteComponent from '../components/PokemonSpriteComponent.vue'
import PokemonTypeComponent from '../components/PokemonTypeComponent.vue'
import PokeAPI from './PokeAPI/PokeAPI.vue'

export default {
  name: 'DexEntry',
  components: {
    PokemonTypeComponent,
    PokemonSpriteComponent,
    PokeAPI
  },
  props: {
    scroll_last_position: {
      type: Function
    },
    number: Number,
  },
  activated: function () {
    this.scroll_last_position()
    this.$store.dispatch('closeAppMenu')
    window.scrollTo(0,0)
  },
  methods: {
    typeNameResolver: PokemonSpecies.typeNameResolver,
    resolveEvolutionChain: PokemonSpecies.evolutionChainResolver,
    upperCase: function (string) {
      try {
        return string.charAt(0).toUpperCase() + string.slice(1)
      } catch (e) {
        return string
      }
    }
  },
  computed: {
    speciesName: function () {
      return this.upperCase(this.$store.state.pokemonSpecies.identifier[this.dexIndex])
    },
    speciesIsLegendary: function () {
      return this.$store.state.pokemonSpecies.is_legendary[this.dexIndex] == 1
    },
    speciesIsMythical: function () {
      return this.$store.state.pokemonSpecies.is_mythical[this.dexIndex] == 1
    },
    hasAlternateForm: function () {
      return this.$store.state.pokemonSpecies.forms_switchable[this.dexIndex] == 1
    },
    hasGenderDifferences: function () {
      return this.$store.state.pokemonSpecies.has_gender_differences[this.dexIndex] == 1
    },
    speciesIsBaby: function () {
      return this.$store.state.pokemonSpecies.is_baby[this.dexIndex] == 1
    },
    generation: function () {
      return this.$store.state.pokemonSpecies.generation_id[this.dexIndex]
    },
    speciesColor: function () {
      return this.bgColor
    },
    speciesTypeText: function () {
      const typeCount = this.pokemonType.length
      let returnText = typeCount == 2 ? 'dual-type ' : 'mono-type '

      for (let type in this.pokemonType) {
        returnText += this.typeNameResolver(this.pokemonType[type].type_id)

        if (type == 0 && typeCount == 2)
          returnText += ' and '
      }

      return returnText
    },
    specialPokemonText: function () {
      if (this.speciesIsLegendary)
        return 'legendary'
      else if (this.speciesIsMythical)
        return 'mythical'
      else if (this.speciesIsBaby)
        return 'baby'
      else
      return ''
    },
    evolutionSummary: function () {
      const evolutionChain = this.evolutionChain

      let summary = ''
      if (evolutionChain.noEvolution)
        summary = `${this.speciesName} does not evolve or evolved from any Pok\u00E9mon.`
      else if (evolutionChain.isFirstStage)
        summary = `${this.speciesName} is the start of its evolution line.  ${this.speciesName} can evolve to ${this.upperCase(evolutionChain.evolveTo.name)}.`
      else if (evolutionChain.isFinalStage)
        summary = `${this.speciesName} is the final evolution of ${this.upperCase(evolutionChain.startOfChain.name)}.`
      else if (!evolutionChain.isFinalStage && !evolutionChain.isFirstStage)
        summary = `${this.speciesName} is a part of the ${this.upperCase(evolutionChain.startOfChain.name)} evolution line. ${this.speciesName} evolve from ${this.upperCase(evolutionChain.evolveFrom.name)} and can evolve to ${this.upperCase(evolutionChain.evolveTo.name)}.`

      return summary
    },
  },
  data: function () {
    let dexNum = parseInt(this.$route.params.number)
    dexNum = Number.isNaN(dexNum) ? 0 : dexNum

    const dexIndex = dexNum - 1
    const bgColor = PokemonColorNames.colorCode[this.$store.state.pokemonSpecies.color_id[dexIndex]]

    return {
      dexIndex,
      dexNum,
      bgColor,
      pokemonType: PokemonSpecies.types[dexNum],
      evolutionChain: this.resolveEvolutionChain(dexIndex),
      style: {
        backgroundColor: bgColor,
        minHeight: '100vh',
        marginTop: '-20px',
      },
    }
  },
}
</script>

<style scoped>
.dex-entry__title {
  padding: 0;
  margin: 0;
}

.uk-card.uk-card-body {
  margin: 0;
  padding: 0;
}

#dex-entry__dex-num {
  position: absolute;
  font-size: 35pt;
  font-weight: bold;
  top: -3px;
  left: 8px;
  color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 639px) {
  .dex-entry {
    padding-left: 0;
    padding-right: 0;
  }

  .dex-entry__summary {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
