<template>
  <div class="variant-stats">
    <PropertyTitle :value="'Stats & EV Yield'" />
    <vk-grid gutter="small">
      <div class="uk-flex-last@m uk-width-1-1 uk-width-3-5@m uk-width-1-2@l">
        <h3 class="uk-text-default">EV Yield</h3>
        <div style="overflow-x: auto;">
          <table class="uk-table uk-table-small uk-table-divider uk-margin-remove">
            <thead>
                <tr>
                    <th v-for="stat in stats" v-bind:key="stat.stat.name">{{ formatStatName(stat.stat.name, true) }}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="uk-text-center">
                    <td v-for="stat in stats" v-bind:key="stat.stat.name">{{ stat.effort }}</td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="uk-width-1-1 uk-width-2-5@m uk-width-1-2@l">
        <h3 class="uk-text-default">Stats</h3>
        <vk-card class="uk-card-secondary">
          <div>
            <StatsComponent
              v-for="stat in stats" v-bind:key="stat.stat.name"
              :statsName="formatStatName(stat.stat.name)"
              :statsVal="stat.base_stat"
              :statsMax="255" />
            <StatsComponent
              :statsName="'Overall Stats'"
              :statsVal="statsTotal.statSum"
              :statsMax="statsTotal.maxScore" />
          </div>
        </vk-card>
      </div>
    </vk-grid>
  </div>
</template>

<script>
import PropertyTitle from './PropertyTitle.vue'
import StatsComponent from './StatsComponent.vue'

export default {
  name: 'PokeAPIVariantStats',
  components: {
    PropertyTitle,
    StatsComponent,
  },
  props: {
    stats: Array
  },
  computed: {
    statsTotal: function () {
      let statSum = 0

      this.stats.forEach(stat => {
        statSum += stat.base_stat
      })

      return {
        statSum,
        maxScore: 255 * this.stats.length
      }
    },
  },
  methods: {
    formatStatName: function (name, shorten) {
      shorten = shorten === undefined ? false : shorten

      if (name == 'hp')
        return 'HP'

      if (shorten) {
        if (name == 'special-attack')
          return 'SP. Atk'
        else if (name == 'special-defense')
          return 'SP. Def'
      }

      return name.split('-')
      .map(function (input) {
        return input.charAt(0).toUpperCase() + input.slice(1)
      })
      .join(' ')
    }
  },
}
</script>

<style scoped>
.uk-form-label {
  font-weight: bold;
}
</style>
