<template>
  <div>
    <PropertyTitle :value="'Variant'" />
    <p>There are {{ varieties.length }} different variants of {{ speciesName }}. Click on the card below to see its stats:</p>
    <vk-grid class="uk-child-width-1-2" gutter="small">
      <div v-for="(variant, index) in varieties" v-bind:key="index">
        <vk-card v-on:click="changeVariant(index)" class="uk-card-hover"
          v-bind:class="{ 'uk-card-primary': selectedVariant == index }"
        >
          <div slot="media-top" class="uk-text-center">
            <PokemonSpriteComponent :speciesNumber="variantId(variant.pokemon.url)" v-bind:alt="variant.pokemon.name" />
          </div>
          <div>
            <vk-card-title class="uk-text-center">{{ variantName(variant.pokemon.name) }}</vk-card-title>
            <div class="uk-card uk-card-body">
              <PokemonTypeComponent :pokemonId="variantId(variant.pokemon.url)" />
            </div>
          </div>
        </vk-card>
      </div>
    </vk-grid>
  </div>
</template>

<script>
import PropertyTitle from './PropertyTitle.vue'
import PokemonSpriteComponent from '../PokemonSpriteComponent.vue'
import PokemonTypeComponent from '../PokemonTypeComponent.vue'

export default {
  name: 'PokeAPIVarieties',
  components: {
    PropertyTitle,
    PokemonSpriteComponent,
    PokemonTypeComponent,
  },
  props: {
    varieties: Array,
    changeVariant: Function,
    speciesName: String,
    selectedVariant: Number
  },
  methods: {
    variantId: function (variantUrl) {
      const urlComponent = variantUrl.split('/')

      for (let i = urlComponent.length - 1; i > 0; --i) {
        const componentContent = Number.parseInt(urlComponent[i])
        if (!Number.isNaN(componentContent)) {
          return componentContent
        }
      }

      return 0
    },
    variantName: function (name) {
      const varName = name.substr(this.speciesName.length + 1)
      .split('-')
      .map(function (input) {
        return input.charAt(0).toUpperCase() + input.slice(1)
      })
      .join(' ')

      return (varName.length == 0) ? 'Normal' : varName
    }
  },
}
</script>

<style scoped>
.uk-card-title {
  margin-bottom: 0;
}

.uk-card-body {
  padding-top: 0;
}

.uk-card-media-top {
  padding-top: 10px;
}

.media-top {
  text-align: center;
}
</style>
