<template>
  <div class="variant-ability">
    <PropertyTitle :value="'Abilities'" />
    <vk-grid class="uk-text-center">
      <div v-for="ability in abilities" class="uk-width-auto" v-bind:key="ability.name">
        <vk-card :class="{ 'uk-card-secondary':ability.is_hidden }" v-on:click="showAbilityModal(ability)">
          <span class="uk-text-capitalize">{{ ability.ability.name.split('-').join(' ') }}</span><span v-if="ability.is_hidden">&nbsp;(Hidden)</span>
        </vk-card>
      </div>
    </vk-grid>
    <AbilityInfo :show="showModal" :ability="requestedAbility" @update-show-state="updateModalState" />
  </div>
</template>

<script>
import PropertyTitle from './PropertyTitle.vue'
import AbilityInfo from './AbilityInfo.vue'

export default {
  name: 'PokeAPIAbilities',
  components: {
    PropertyTitle,
    AbilityInfo,
  },
  props: {
    abilities: Array,
  },
  data: function () {
    return {
      showModal: false,
      requestedAbility: null,
    }
  },
  methods: {
    showAbilityModal: function (ability) {
      this.requestedAbility = ability
      this.showModal = true
    },
    updateModalState: function (newState) {
      this.showModal = newState
    },
  },
}
</script>

<style scoped>
</style>
