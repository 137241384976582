<template>
  <div class="uk-flex uk-flex-center">
    <div class="dex-entry__type" v-for="type in pokemonType" v-bind:key="type.slot">
      <div class="uk-label">{{ typeNameResolver(type.type_id) }}</div>
    </div>
  </div>
</template>

<script>
import PokemonSpecies from '@/dataStorage/pokemonSpecies'

export default {
  name: 'PokemonTypeComponent',
  props: {
    pokemonId: Number,
  },
  methods: {
    typeNameResolver: PokemonSpecies.typeNameResolver,
  },
  computed: {
    pokemonType: function () {
      return PokemonSpecies.types[this.pokemonId]
    },
  }
}
</script>

<style scoped>
.dex-entry__type:first-child {
  margin-right: 5px;
}
</style>
