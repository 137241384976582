<template>
  <vk-grid>
    <form class="uk-width-1-1 uk-width-2-3@s">
      <h2 class="uk-text-capitalize" v-if="dexNum != 0">{{ monsterName }}</h2>
      <h2 v-if="dexNum == 0">Defending type</h2>
      <p v-if="dexNum != 0"><router-link
        :to="{ name: 'DexEntry', params: { number: dexNum }}">Click here to read the Pokedex entry for <span class="uk-text-capitalize">{{ monsterName }}</span>.</router-link></p>

      <div class="uk-width-medium@s uk-width-1-1 attack-efficacy__search-by-mon" @mouseover="showsearchResult = true" @mouseleave="showsearchResult = false">
        <div class="uk-flex uk-flex-left uk-flex-middle">
          <div style="margin-right: 10px;">
            <vk-icon icon="search"></vk-icon>
          </div>
          <div class="uk-width-1-1">
            <input class="uk-input" style="width: 100%" type="search" placeholder="Search Pokemon" v-model="search_param" @focus="userEnteringKeyword = true" @blur="userEnteringKeyword = false">
          </div>
        </div>
        <vk-card padding="small" class="uk-width-1-1" v-if="(showsearchResult || userEnteringKeyword) && search_param.length >= 3">
          <table class="uk-table uk-table-hover">
              <caption><b>Search Result ({{searchResults.identifier.length}} {{plural('result', searchResults.identifier.length)}} found)</b></caption>
              <tbody>
                  <tr v-for="result in searchResults.identifier.length" v-bind:key="searchResults.id[result - 1]" @click="fromMonster(searchResults.id[result - 1])">
                      <td>{{ searchResults.identifier[result - 1] }}</td>
                  </tr>
              </tbody>
          </table>
        </vk-card>
      </div>
      <legend class="uk-legend">Primary type</legend>
      <TypeSelector
        :disabledType="0"
        :name="'primaryType'"
        :onChangeType="onTypeChange(1)"
        :activeType="primaryType"
        key="primaryType" />

      <legend class="uk-legend">Secondary type</legend>
      <TypeSelector
        :showNoneType="true"
        :disabledType="primaryType"
        :name="'secondaryType'"
        :onChangeType="onTypeChange(2)"
        :activeType="secondaryType"
        key="secondaryType" />
    </form>

    <div class="uk-width-1-1 uk-width-1-3@s">
      <div class="uk-container">
        <h2>Attacked by</h2>
        <TypeEfficacyDisplay :typeEfficacies="typeEfficacies" />
      </div>
    </div>
  </vk-grid>
</template>

<script>
// @ is an alias to /src
import DamageMatrix from '../dataStorage/typeDamageMatrix'
import PokemonSpecies from '../dataStorage/pokemonSpecies'
import TypeSelector from './TypeSelector.vue'
import TypeEfficacyDisplay from './TypeEfficacyDisplay.vue'

export default {
  name: 'AttackEfficacy',
  props: {

  },
  components: {
    TypeSelector,
    TypeEfficacyDisplay,
  },
  data: function () {
    return {
      types: DamageMatrix.types,
      primaryType: 1,
      secondaryType: 0,
      showsearchResult: false,
      userEnteringKeyword: false,
      search_param: '',
      dexNum: 0,
      monsterName: '',
    }
  },
  methods: {
    plural: function (text, len) {
      if (len == 1)
        return text
      return text + 's'
    },
    fromMonster: function (id) {
      const monsterType = PokemonSpecies.types[id]

      this.primaryType = monsterType[0].type_id
      this.secondaryType = monsterType[1]?.type_id
      this.dexNum = id
      this.monsterName = this.$store.state.pokemonSpecies.identifier[this.dexNum - 1]

      this.showsearchResult = false
    },
    onTypeChange: function (typeSlot) {
      return (newType) => {
        if (typeSlot == 1) {
          this.primaryType = newType
          return
        }

        this.secondaryType = newType
      }
    }
  },
  computed: {
    typeEfficacies: function () {
      const efficacies = {}
      const defendingTypes = [this.primaryType]

      if (this.primaryType != this.secondaryType && this.secondaryType != 0)
        defendingTypes.push(this.secondaryType)

      for (let attackTypeKey in DamageMatrix.types) {
        let efficacy = 1
        let attackWith = this.types[attackTypeKey]

        for (let defendWith = 0; defendWith < defendingTypes.length; ++defendWith) {
          defendWith = Number.parseInt(defendWith)
          defendWith = Number.isNaN(defendWith) ? 0 : defendWith

          efficacy *= DamageMatrix[attackWith][defendingTypes[defendWith]]
        }

        if (efficacies[efficacy] === undefined)
          efficacies[efficacy] = []

        efficacies[efficacy].push(attackWith)
        efficacies[efficacy].sort((a, b) => {
          if (PokemonSpecies.typeNameResolver(a) >= PokemonSpecies.typeNameResolver(b))
            return 1
          return -1
        })
      }

      return efficacies
    },
    searchResults: function () {
      const searchResults = this.$store.state.languageModel.search(this.search_param)
      const returnResult = {id: [], identifier: []}
      const closestMatchThreshold = 0.01
      let closestMatchFound = searchResults[0].score <= closestMatchThreshold

      for (let i in searchResults) {
        if (closestMatchFound && searchResults[i].score > closestMatchThreshold)
          break

        returnResult.id.push(searchResults[i].refIndex + 1)
        returnResult.identifier.push(searchResults[i].item)
      }

      return returnResult
    },
  },
  watch: {
    secondaryType: function (newVal) {
      if (newVal === undefined)
        this.secondaryType = 0
    }
  },
  activated: function () {
    if (this.$route.params.tab === 'defense') {
      let dexNum = Number.parseInt(this.$route.params.slug)

      if (Number.isNaN(dexNum))
        return

      this.fromMonster(dexNum)
    }
  }
}
</script>

<style scoped>
.type-calc__type-selector {
  width: 120px;
}

.uk-legend {
  margin-top: 30px;
  margin-bottom: 10px;
}

.attack-efficacy__search-by-mon {
  position: relative;
}

.attack-efficacy__search-by-mon .uk-card {
  position: absolute;
  max-height: 50vh;
  overflow: scroll;
}

.attack-efficacy__search-by-mon .uk-card table {
  text-transform: capitalize;
}
</style>
