<template>
  <div class="dex uk-container uk-margin">
    <SearchBox :onValueChange="newVal => { search_param = newVal }" />

    <PokemonGrid :search_param="search_param" />
  </div>
</template>

<script>
// @ is an alias to /src
import PokemonGrid from '../components/PokemonGrid.vue'
import SearchBox from '../components/SearchBox.vue'

export default {
  name: 'Dex',
  components: {
    PokemonGrid,
    SearchBox,
  },
  props: {
    scroll_last_position: {
      type: Function
    },
  },
  activated: function () {
    this.scroll_last_position()
    this.$store.dispatch('closeAppMenu')
  },
  data: function () {
    return {
      search_param: ''
    }
  },
}
</script>

<style scoped>

</style>
