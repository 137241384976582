<template>
  <div class="uk-container move-info uk-container-expand" :style="containerStyle">
    <div class="uk-container uk-padding">
      <vk-grid class="uk-flex-center">
        <div class="uk-width-1-1 uk-width-3-4@s uk-width-1-2@m">
          <vk-card class="uk-card uk-card-small uk-card-default move-info__card">
            <button class="uk-button uk-button-link" @click="$router.go(-1)">&#8592; Back</button>
            <hr class="uk-divider-icon">
            <h1 class="uk-text-capitalize">{{ moveName }}</h1>
            <p class="uk-width-1-1 uk-width-1-2@s">Pound is a non-damaging normal-type physical move introduced in generation I.</p>
            <p><router-link
              :to="{ name: 'TypeCalc', params: { tab: 'offense', slug: moveType }}">Click here to view the type efficacy of <span class="uk-text-capitalize">{{ moveName }}</span>.</router-link></p>
            <div class="uk-width-1-1" style="overflow-x: auto;">
              <table class="uk-table uk-table-divider">
                <thead>
                  <tr>
                    <th class="uk-text-center">Type</th>
                    <th class="uk-text-center">Damage Class</th>
                    <th class="uk-text-center">Power</th>
                    <th class="uk-text-center">PP</th>
                    <th class="uk-text-center">Accuracy</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="uk-text-center uk-text-middle">
                      <div class="uk-felx uk-flex-center">
                        <div style="width: 80px; display: inline-block;">
                          <TypeDisplay
                            :typeId="moveType"
                            :renderSmall="true" />
                        </div>
                      </div>
                    </td>
                    <td class="uk-text-capitalize uk-text-center uk-text-middle">{{ damageClassResolver(moves_.damage_class_id[moveIdx]) }}</td>
                    <td class="uk-text-center uk-text-middle">{{ numOrDash(moves_.power[moveIdx]) }}</td>
                    <td class="uk-text-center uk-text-middle">{{ numOrDash(moves_.pp[moveIdx]) }}</td>
                    <td class="uk-text-center uk-text-middle">{{ numOrDash(moves_.accuracy[moveIdx]) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <APIDataLoading
              :fetchingApiData="fetchingApiData"
              :apiRespInvalid="apiRespInvalid"
              :apiErrorMessage="apiErrorMessage" />
          </vk-card>
        </div>
      </vk-grid>
    </div>
  </div>
</template>

<script>
import APIDataLoading from '../components/APIDataLoading.vue'
import TypeDisplay from '../components/TypeDisplay.vue'
import PokemonSpecies from '../dataStorage/pokemonSpecies'
import PokemonMoves from '../dataStorage/pokemonMoves'

export default {
  name: 'MovesInfo',
  components: {
    APIDataLoading,
    TypeDisplay,
  },
  data: function () {
    let moveId = Number.parseInt(this.$route.params.moveId)
    moveId = Number.isNaN(moveId) ? 0 : moveId

    const moveIdx = this.$store.state.pokemonMoves.id.indexOf(moveId)
    const moves_ = this.$store.state.pokemonMoves
    const attackTypeColor = PokemonSpecies.typeColorResolver(moves_.type_id[moveIdx])

    return {
      moves_,
      moveId,
      moveIdx,
      containerStyle: {
        backgroundColor: attackTypeColor,
      },
      attackTypeStyle: {
        backgroundColor: attackTypeColor,
      },

      fetchingApiData: true,
      apiRespInvalid: false,
      apiErrorMessage: '',
    }
  },
  methods: {
    typeNameResolver: PokemonSpecies.typeNameResolver,
    typeColorResolver: PokemonSpecies.typeColorResolver,
    damageClassResolver: PokemonMoves.damageClassResolver,
    numOrDash: function (num) {
      if (Number.isInteger(num))
        return num

      return String.fromCodePoint(8213)
    },
  },
  computed: {
    moveName: function () {
      return this.moves_.identifier[this.moveIdx].split('-').join(' ')
    },
    moveType: function () {
      return this.moves_.type_id[this.moveIdx]
    },
  },
}
</script>

<style scoped>
.move-info {
  margin-top: -20px;
  min-height: calc(100vh - 60px);
}

.move-info__card {
  background-color: rgba(255, 255, 255, 0.93);
}
</style>
