<template>
  <div id="appMenu-container">
    <vk-offcanvas-content>
      <vk-offcanvas overlay :show="show">
        <vk-offcanvas-close @click="closeAppMenu()"></vk-offcanvas-close>
        <h3>PKMN Utility</h3>
        <div class="uk-width-1-1">
          <vk-nav type="primary">
            <router-link to="/dex" custom v-slot="{href, navigate, isActive}">
              <li :class="[isActive && 'uk-active']">
                <a :href="href" @click="navigate">Dex</a>
              </li>
            </router-link>

            <router-link to="/move" custom v-slot="{href, navigate, isActive}">
              <li :class="[isActive && 'uk-active']">
                <a :href="href" @click="navigate">Moves</a>
              </li>
            </router-link>

            <router-link to="/typeCalculator" custom v-slot="{href, navigate, isActive}">
              <li :class="[isActive && 'uk-active']">
                <a :href="href" @click="navigate">Type calculator</a>
              </li>
            </router-link>
          </vk-nav>
        </div>
      </vk-offcanvas>
      <div v-if="show" class="appMenu-container__shadow" v-on:click="hide"></div>
    </vk-offcanvas-content>
  </div>
</template>

<script>
export default {
  name: 'AppFunctionMenu',
  props: {

  },
  computed: {
    show: function () {
      return this.$store.state.appFunctionMenuOpened
    },
  },
  methods: {
    closeAppMenu: function () {
      this.$store.dispatch('closeAppMenu')
    },
    changeFunctionality: function (functionName) {
      this.$store.commit('SET_ACTIVE_VIEW', functionName)
      this.closeAppMenu()
    },
    hide: function () {
      this.$store.state.appFunctionMenuOpened = false
    },
  },
}
</script>

<style scoped>
#appMenu-container {
  position: fixed;
  z-index: 1000;
}

a {
  text-align: left;
}

.appMenu-container__shadow {
  background: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
}

.uk-offcanvas-overlay {
  width: 0;
}

.uk-offcanvas-overlay:before {
  display: none;
}
</style>
