<template>
  <div class="uk-text-center dex-grid__child">
    <vk-card @click="viewDetails">
      <div slot="media-top">
        <PokemonSpriteComponent :speciesNumber="speciesNumber" />
      </div>
      <div>
        <vk-card-title>{{ capitalize(speciesName) }}</vk-card-title>
      </div>
    </vk-card>
  </div>
</template>

<script>
import PokemonSpriteComponent from './PokemonSpriteComponent.vue'

export default {
  name: 'PokemonGridChild',
  props: {
    speciesName: String,
    speciesNumber: Number,
  },
  components: {
    PokemonSpriteComponent
  },
  methods: {
    capitalize: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    viewDetails: function () {
      this.$router.push({ name: 'DexEntry', params: { number: this.speciesNumber } })
    }
  }
}
</script>

<style scoped>
.dex-grid__child {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
