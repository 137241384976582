import Vue from 'vue'
import Vuex from 'vuex'
import Fuse from 'fuse.js'

import PokemonSpecies from '../dataStorage/pokemonSpecies'
import PokemonMoves from '../dataStorage/pokemonMoves'
const fetch = require("node-fetch")

Vue.use(Vuex)

const UPDATE_POKEMON_SPECIES = 'UPDATE_POKEMON_SPECIES'
const SET_LOADING = 'SET_LOADING'
const SET_INITIAL_LOADING = 'SET_INITIAL_LOADING'
const SET_LANGUAGE_MODEL = 'SET_LANGUAGE_MODEL'
const SET_GAME_GENERATION = 'SET_GAME_GENERATION'
const SET_MOVE_DATA = 'SET_MOVE_DATA'

const GAME_VERSION_CACHE_EXPIRE_KEY = 'gameVersionCacheExp'
const GAME_VERSION_CACHE_KEY = 'gameVersionsCache'

async function downloadGameVersions () {
  const versionFetch = await fetch(
    'https://beta.pokeapi.co/graphql/v1beta',
    {
      method: "POST",
      body: JSON.stringify({
        query: 'query getGenerations { \
          versionGroup: pokemon_v2_versiongroup { \
            groupName: name \
            generation_id \
            versions: pokemon_v2_versions { \
              versionName: pokemon_v2_versionnames(where: {language_id: {_eq: 9}}) { \
                name \
              } \
            } \
          } \
        }'
      })
    }
  )
  return (await versionFetch.json()).data
}

async function getGameVersions () {
  // Local storage does not exist. Ignore cache
  if (typeof localStorage === 'undefined') {
    return (await downloadGameVersions())
  } else { // Download new data and cache it
    const cacheExp = localStorage.getItem(GAME_VERSION_CACHE_EXPIRE_KEY)
    if (!cacheExp || (new Date(cacheExp)) < (new Date())) {
      const newVersions = await downloadGameVersions()
      const now = new Date()
      const nextWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate()+7)

      localStorage.setItem(GAME_VERSION_CACHE_EXPIRE_KEY, nextWeek.toISOString())
      localStorage.setItem(GAME_VERSION_CACHE_KEY, JSON.stringify(newVersions))
    }
  }

  return JSON.parse(localStorage.getItem(GAME_VERSION_CACHE_KEY))
}

const mapState = Vuex.mapState

const store = new Vuex.Store({
  state: {
    pokemonSpecies: {},
    initialLoading: true,
    loading: false,
    appFunctionMenuOpened: false,
    languageModel: null,
    generations: {},
    pokemonMoves: {},
    movesLanguageModel: null,
  },
  mutations: {
    [UPDATE_POKEMON_SPECIES] (state, species) {
      state.pokemonSpecies = species
    },
    [SET_LOADING] (state, loading) {
      state.loading = loading
    },
    [SET_INITIAL_LOADING] (state, initialLoading) {
      state.initialLoading = initialLoading
    },
    [SET_LANGUAGE_MODEL] (state) {
      state.languageModel = new Fuse(state.pokemonSpecies.identifier, {
        includeScore: true,
        minMatchCharLength: 2,
        threshold: 0.5,
      })
    },
    [SET_GAME_GENERATION] (state, versions) {
      const versionGroup = {}
      let sortOrder = 0

      for (let v in versions.versionGroup) {
        const vg = versions.versionGroup[v]
        const groupName = vg.groupName
        versionGroup[groupName] = {
          title: '',
          order: sortOrder
        }
        ++sortOrder

        for (let i = 0; i < vg.versions.length; ++i) {
          const versionName = vg.versions[i]?.versionName[0]?.name

          if (versionName === undefined) {
            versionGroup[groupName].title +=  groupName.split('-').join(' ')
            break
          } else {
            versionGroup[groupName].title +=  versionName
          }

          if (i != vg.versions.length - 1)
            versionGroup[groupName].title +=  vg.versions.length == 2 ? ' & ' : ', '
        }
      }

      state.generations = versionGroup
    },
    [SET_MOVE_DATA] (state, moves) {
      state.pokemonMoves = moves

      state.movesLanguageModel = new Fuse(moves.identifier, {
        includeScore: true,
        minMatchCharLength: 2,
        threshold: 0.5,
      })
    },
  },
  getters: {
    pokemonSpecies (state) {
      return state.pokemonSpecies
    },
  },
  actions: {
    async preloadData({commit}) {
      PokemonSpecies.evolutionChainResolver(0)
      await commit(UPDATE_POKEMON_SPECIES, await PokemonSpecies.getAll())
      await commit(SET_MOVE_DATA, PokemonMoves.Moves)

      commit(SET_LANGUAGE_MODEL)
      commit(SET_INITIAL_LOADING, false)
      commit(SET_GAME_GENERATION, await getGameVersions())
      // return fetchJsonp(api)
      //   .then((users) => users.json())
      //   .then((usersParsed) => {
      //     commit(UPDATE_USERS, usersParsed)
      //     commit(SET_LOADING, false)
      //   })
    },
    openAppMenu ({commit}) {
      !commit
      this.state.appFunctionMenuOpened = true

      // const app = document.getElementById('app')
      // app.style.height = '100vh'
    },
    closeAppMenu ({commit}) {
      !commit
      this.state.appFunctionMenuOpened = false

      // const app = document.getElementById('app')
      // app.style.height = ''
    },
  },
  modules: {
  }
})

export default {
  mapState,
  store
}
