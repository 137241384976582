<template>
  <div class="defenseEfficacy">
    <vk-grid>
      <div class="uk-width-1-1 uk-width-2-3@s">
        <legend class="uk-legend">Attack type</legend>
        <TypeSelector :onChangeType="onChangeType" :activeType="attackingType"  />
      </div>

      <div class="uk-width-1-1 uk-width-1-3@s">
        <legend class="uk-legend">Efficacy when attacking</legend>
        <TypeEfficacyDisplay :typeEfficacies="typeEfficacies" />
      </div>
    </vk-grid>
  </div>
</template>

<script>
import DamageMatrix from '../dataStorage/typeDamageMatrix'
import TypeSelector from './TypeSelector.vue'
import TypeEfficacyDisplay from './TypeEfficacyDisplay.vue'

export default {
  name: 'DefenseEfficacy',
  props: {
    msg: String
  },
  components: {
    TypeSelector,
    TypeEfficacyDisplay
  },
  data: function () {
    return {
      attackingType: 1,
    }
  },
  methods: {
    onChangeType: function (newType) {
      this.attackingType = newType
    },
  },
  computed: {
    typeEfficacies: function () {
      const efficacies = {}
      const defendingTypes = Object.keys(DamageMatrix.types)

      for (let defendingTypeName in defendingTypes) {
        const defendingTypeId = DamageMatrix.types[defendingTypes[defendingTypeName]]
        const efficacy = DamageMatrix[this.attackingType][defendingTypeId]

        if (efficacies[efficacy] === undefined)
          efficacies[efficacy] = []

        efficacies[efficacy].push(defendingTypeId)
      }

      console.log(efficacies)
      return efficacies
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.uk-legend {
  margin-top: 30px;
  margin-bottom: 10px;
}
</style>
