<template>
  <vk-modal :show.sync="show_">
    <vk-modal-close></vk-modal-close>

    <APIDataLoading
      :fetchingApiData="fetchingApiData"
      :apiRespInvalid="apiRespInvalid"
      :apiErrorMessage="apiErrorMessage" />

    <vk-modal-title v-if="apiRequestSuccess" class="uk-text-capitalize">{{ apiResp.name.split('-').join(' ') }}</vk-modal-title>
    <p class="uk-text-meta" v-if="apiRequestSuccess">Ability</p>
    <p v-if="apiRequestSuccess">{{ abilityDesc }}</p>
  </vk-modal>
</template>

<script>
import PokeAPI from '@/dataStorage/pokeAPI'
import APIDataLoading from '../APIDataLoading.vue'

export default {
  name: 'PokeAPIAbilityInfo',
  components: {
    APIDataLoading
  },
  props: {
    ability: Object,
    accessTime: String,
    show: Boolean,
  },
  data: function () {
    return {
      fetchingApiData: true,
      apiRespInvalid: false,
      apiErrorMessage: '',
      apiResp: {},
      show_: false,
    }
  },
  methods: {
    resetApiReqState: function () {
      this.fetchingApiData = true
      this.apiRespInvalid = false
    },
    setApiResp: function (resp) {
      this.apiResp = resp
    },
    setApiError: function (err) {
      this.apiRespInvalid = true
      this.apiErrorMessage = err
    },
    doneFetchingData: function () {
      this.fetchingApiData = false
    },
  },
  computed: {
    abilityDesc: function () {
      for (let entry in this.apiResp.effect_entries) {
        if (this.apiResp.effect_entries[entry].language.name === 'en')
          return this.apiResp.effect_entries[entry].effect
      }

      return ''
    },
    apiRequestSuccess: function () {
      return !this.fetchingApiData && !this.apiRespInvalid
    }
  },
  watch: {
    show_: function (newState) {
      this.$emit('update-show-state', newState)
    },
    show: function (newState) {
      if (newState != this.show_)
        this.show_ = newState
    },
    ability: function () {
      this.resetApiReqState()

      if (this.ability === null)
        return

      this.show_ = true
      PokeAPI.getAbilityInfo(this.ability).then((resp) => {
        this.setApiResp(resp)
      })
      .catch((err) => {
        this.setApiError(err)
      })
      .finally(() => { this.doneFetchingData() })
    },
  },
}
</script>

<style scoped>
.uk-modal-title {
  margin-bottom: 5px;
}

.uk-text-meta {
  margin-top: 5px;
  font-size: 13pt;
}
</style>
