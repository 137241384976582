<template>
  <div class="RemoteResourceLoader" v-if="fetchingApiData || !apiRespInvalid">
    <SkeletonLoader v-if="fetchingApiData" />
    <div uk-alert v-if="apiRespInvalid">
      <h3>Notice</h3>
      <p>An error occured when processing your request.<br /><pre>{{apiErrorMessage}}</pre></p>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from './SkeletonLoader.vue'

export default {
  name: 'RemoteResourceLoader',
  components: {
    SkeletonLoader,
  },
  props: {
    fetchingApiData: Boolean,
    apiRespInvalid: Boolean,
    apiErrorMessage: String,
  },
}
</script>

<style scoped>

</style>
