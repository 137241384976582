var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"appMenu-container"}},[_c('vk-offcanvas-content',[_c('vk-offcanvas',{attrs:{"overlay":"","show":_vm.show}},[_c('vk-offcanvas-close',{on:{"click":function($event){return _vm.closeAppMenu()}}}),_c('h3',[_vm._v("PKMN Utility")]),_c('div',{staticClass:"uk-width-1-1"},[_c('vk-nav',{attrs:{"type":"primary"}},[_c('router-link',{attrs:{"to":"/dex","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'uk-active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Dex")])])]}}])}),_c('router-link',{attrs:{"to":"/move","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'uk-active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Moves")])])]}}])}),_c('router-link',{attrs:{"to":"/typeCalculator","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'uk-active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Type calculator")])])]}}])})],1)],1)],1),(_vm.show)?_c('div',{staticClass:"appMenu-container__shadow",on:{"click":_vm.hide}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }