<template>
  <div class="skeleton-loader__container" :class="customClass">
    <APIDataLoading
      :fetchingApiData="fetchingApiData"
      :apiRespInvalid="apiRespInvalid"
      :apiErrorMessage="apiErrorMessage" />

    <div v-if="!fetchingApiData && !apiRespInvalid">
      <div class="uk-article uk-width-large@s">
        <p>{{ speciesDescription.flavor_text }}</p>
        <p class="uk-article-meta">&horbar; Pokemon <span class="uk-text-capitalize">{{ speciesDescription.version.name.split('-').join(' ') }}</span> pokedex</p>
      </div>
      <PokeAPIVarieties
        v-if="apiResp.varieties.length > 1"
        :selectedVariant="variantIndex"
        :changeVariant="changeVariant"
        :varieties="apiResp.varieties"
        :speciesName="speciesName"
        class="dex__component" />
      <keep-alive>
        <PokeAPIVariantDetail
          :key="speciesName + variantIndex"
          :variantIndex="variantIndex"
          :varieties="apiResp.varieties"
          class="dex__component" />
      </keep-alive>
      <div v-for="(value, name) in apiResp" v-bind:key="name">
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
import APIDataLoading from '../../components/APIDataLoading.vue'
import PokeAPI from '@/dataStorage/pokeAPI'
import PokeAPIView from './PokeAPI.vue'

import PokeAPIVarieties from '../../components/PokeAPI/varieties.vue'
import PokeAPIVariantDetail from '../../components/PokeAPI/variantDetail.vue'
!PokeAPIView

export default {
  name: 'PokeAPI',
  components: {
    APIDataLoading,
    PokeAPIVarieties,
    PokeAPIVariantDetail,
  },
  props: {
    dexNum: Number,
    rootView: Boolean,
    customClass: String,
    fetchAddress: String,
    speciesName: String,
  },
  data: function () {
    return {
      fetchingApiData: true,
      apiResp: {},
      apiRespInvalid: false,
      apiErrorMessage: '',
      variantIndex: 0,
      counter: 0,
      descriptionTimer: null,
    }
  },
  methods: {
    doneFetchingData: function () {
      this.fetchingApiData = false
    },
    setApiError: function (err) {
      this.apiErrorMessage = err
      this.apiRespInvalid = true
    },
    setApiResp: function (resp) {
      this.apiResp = resp

      // Find the default variant
      if (this.apiResp.varieties.length > 1) {
        for (let i = 0; i < this.apiResp.varieties.length; ++i) {
          if (this.apiResp.varieties[i].is_default) {
            this.variantIndex = i
            break
          }
        }
      }
    },
    changeVariant: function (variantIndex) {
      this.variantIndex = variantIndex
    }
  },
  computed: {
    speciesDescription: function () {
      !this.now
      const desc = this.apiResp.flavor_text_entries.filter(flavorText => {
        if (flavorText.language.name != 'en')
          return false

        return true
      }).map(flavorText => {
        return {
          flavor_text: flavorText.flavor_text,
          version: flavorText.version,
        }
      }).sort((a, b) => {
        if (a.version.url > b.version.url)
          return 1
        return -1
      })

      return desc[this.counter % desc.length]
    }
  },
  created: function () {
    let self = this
    this.descriptionTimer = setInterval(function () {
      self.counter++
    }, 10000)

    PokeAPI.getPokemonByDexNum(this.dexNum).then((resp) => {
      this.setApiResp(resp)
    })
    .catch((err) => {
      this.setApiError(err)
    })
    .finally(() => { this.doneFetchingData() })
  },
  beforeUnmount: function () {
    clearInterval(this.descriptionTimer)
  }
}
</script>

<style scoped>
.dex__component {
  margin: 30px 0;
}

.dex-entry__pokemon-desc-timer {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
}

.dex-entry__pokemon-desc-timer:before {
  content: ' ';
  display: block;
  width: 50%;
  height: 100%;
  background: rgba(0, 0, 255, 0.2);
  position: absolute;

  animation-duration: 10s;
  animation-name: loadingBar;
  animation-iteration-count: infinite;
}

@keyframes loadingBar {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

</style>
