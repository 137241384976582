<template>
  <div>
    <APIDataLoading
      :fetchingApiData="fetchingApiData"
      :apiRespInvalid="apiRespInvalid"
      :apiErrorMessage="apiErrorMessage" />

    <div v-if="!fetchingApiData && !apiRespInvalid">
      <PokeAPIAbilities :abilities="apiResp.abilities" class="dex__component__details" />
      <VariantStats :stats="apiResp.stats" class="dex__component__details" />
      <PokeAPIMovesets :moves="apiResp.moves" class="dex__component__details" :pokemonType="variantType" />
      <PokeAPISprites :sprites="apiResp.sprites" class="dex__component__details" />
    </div>
  </div>
</template>

<script>
import APIDataLoading from '../APIDataLoading.vue'
import PokeAPI from '@/dataStorage/pokeAPI'
import VariantStats from './variantStats.vue'
import PokeAPIMovesets from '../../components/PokeAPI/variantMovesets.vue'
import PokeAPIAbilities from '../../components/PokeAPI/variantAbilities.vue'
import PokeAPISprites from '../../components/PokeAPI/variantSprite.vue'
import PokemonSpecies from '@/dataStorage/pokemonSpecies'

export default {
  name: 'PokeAPIVariantDetail',
  components: {
    APIDataLoading,
    VariantStats,
    PokeAPIMovesets,
    PokeAPIAbilities,
    PokeAPISprites,
  },
  props: {
    varieties: Array,
    variantIndex: Number,
  },
  data: function () {
    return {
      fetchingApiData: true,
      apiRespInvalid: false,
      apiErrorMessage: '',
      apiResp: {},
    }
  },
  methods: {
    setApiResp: function (resp) {
      this.apiResp = resp
    },
    setApiError: function (err) {
      this.apiRespInvalid = true
      this.apiErrorMessage = err
    },
    doneFetchingData: function () {
      this.fetchingApiData = false
    }
  },
  created: function () {
    PokeAPI.getVarietyDetail(this.varieties, this.variantIndex).then((resp) => {
      console.log(resp)
      this.setApiResp(resp)
    })
    .catch((err) => {
      this.setApiError(err)
    })
    .finally(() => { this.doneFetchingData() })
  },
  computed: {
    variantType: function () {
      let dexNum = this.varieties[this.variantIndex].pokemon.url
        .split('/')
        .filter((ent, idx, arr) => {
          return !(ent == '' && idx == arr.length - 1)
        })
        .slice(-1)[0]
      dexNum = Number.isNaN(dexNum) ? 0 : dexNum

      return PokemonSpecies.types[dexNum].map(ent => {
        return ent.type_id
      })
    },
  },
}
</script>

<style scoped>
.dex__component__details {
  margin: 30px 0;
}
</style>
