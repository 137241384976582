<template>
  <div class="uk-container TypeCalc uk-padding">
    <div class="uk-margin-medium-top">
      <vk-tabs align="center" :keepAlive="true" :active-tab="activeTab">
        <vk-tabs-item title="Defense" key="defense">
          <AttackEfficacy />
        </vk-tabs-item>

        <vk-tabs-item title="Offense" key="offense">
          <DefenseEfficacy />
        </vk-tabs-item>

        <vk-tabs-item title="Attack Coverage" key="attackCoverage">coming soon! :)</vk-tabs-item>
        <vk-tabs-item title="Team Coverage" key="teamCoverage">coming soon! :)</vk-tabs-item>
      </vk-tabs>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AttackEfficacy from '../components/AttackEfficacy.vue'
import DefenseEfficacy from '../components/DefenseEfficacy.vue'

export default {
  name: 'TypeCalc',
  components: {
    AttackEfficacy,
    DefenseEfficacy,
  },
  data: function () {
    return {
      activeTab: 'AttackEfficacy',
    }
  },
  methods: {

  },
  computed: {
  },
  created: function () {
    const availableTab = ['defense', 'offense', 'attackCoverage']

    if (availableTab.indexOf(this.$route.params.tab) != -1)
      this.activeTab = this.$route.params.tab
    else
      this.activeTab = 'defense'
  },
  activated: function () {
    this.$store.dispatch('closeAppMenu')
  },
}
</script>
