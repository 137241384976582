<template>
  <div class="uk-text-capitalize type__container" :class="containerClass" :style="componentStyle">
    {{ typeName }}
  </div>
</template>

<script>
import PokemonSpecies from '../dataStorage/pokemonSpecies'

export default {
  name: 'MovesInfo',
  props: {
    typeId: Number,

    renderSmall: Boolean,
  },
  methods: {},
  data: function () {
    return {
      containerClass: {
        'type__container__is-small': this.renderSmall,
      },
      componentStyle: {
        backgroundColor: PokemonSpecies.typeColorResolver(this.typeId),
      }
    }
  },
  computed: {
    typeName: function () {
      console.log(this.typeId, PokemonSpecies.typeNameResolver(this.typeId))
      return PokemonSpecies.typeNameResolver(this.typeId)
    },
  },
}
</script>

<style scoped>
.type__container {
  color: #ffffff;
  text-shadow: 0 1px 0 #000,0 0 1px rgba(0,0,0,.6),0 0 2px rgba(0,0,0,.3),0 0 3px rgba(0,0,0,.6),0 0 4px rgba(0,0,0,.3);
  -webkit-font-smoothing: antialiased;
  padding: 5px 10px;
  width: 100px;
  font-weight: bold;
  text-align: center;
}

.type__container.type__container__is-small {
  font-size: 12px;
  width: 60px;
}
</style>
