<template>
  <div class="move uk-container">
    <SearchBox class="uk-padding" :onValueChange="newVal => { search_param = newVal }" />

    <vk-grid class="uk-flex-center">
      <!-- Filter -->
      <div class="uk-width-1-1 uk-width-1-3@s">
        <vk-grid>
          <div class="uk-width-1-1">
            <h2 style="display: block;" class="uk-width-1-1">Filters</h2>
          </div>
          <div class="uk-width-1-1">
            <h3 style="display: block;" class="uk-width-1-1 uk-margin-small">Type<button class="uk-button uk-button-small uk-button-default" v-if="typeFilters.length > 0" @click="typeFilters = []" style="margin-left: 10px">Reset</button></h3>
            <label
              v-for="typeId in 18"
              v-bind:style="{ backgroundColor: typeColorResolver(typeId)}"
              v-bind:key="typeId"
              :for="'type_' + typeId"
              class="uk-text-capitalize moves-filter__input-block moves-filter__input-block__attack-type"><input type="checkbox" :id="'type_' + typeId" :name="'type_' + typeId" :value="typeId" v-model="typeFilters">&nbsp;{{ typeNameResolver(typeId) }}</label>
          </div>

          <div class="uk-width-1-1">
            <h3 style="display: block;" class="uk-width-1-1 uk-margin-small">Damage class<button class="uk-button uk-button-small uk-button-default" v-if="damageClassFilters.length > 0" @click="damageClassFilters = []" style="margin-left: 10px">Reset</button></h3>
            <label v-for="classId in 4" v-bind:key="classId" :for="'class_' + classId" class="uk-text-capitalize moves-filter__input-block"><input type="checkbox" :id="'class_' + classId" :name="'class_' + classId" :value="classId == 4 ? null : classId" v-model="damageClassFilters">&nbsp;{{ damageClassResolver(classId == 4 ? null : classId) }}</label>
          </div>
        </vk-grid>
      </div>

      <!-- Result table -->
      <div class="uk-width-1-1 uk-width-2-3@s" v-if="moves.length == 0">
        <p class="uk-text-lead">No result found.</p>
        <p>Try changing the filter or search keyword.</p>
      </div>

      <div class="uk-width-1-1 uk-width-2-3@s" style="overflow: scroll"  v-if="moves.length != 0">
        <div class="uk-width-1-1 uk-width-2-3@s" style="display: block;">
          <p>Showing {{ moves.length }} move{{ (moves.length == 1 ? '' : 's') }}. <button v-if="sortBy !== 'id'" class="uk-button uk-button-default uk-button-small" @click="() => {setSortMethod('id');sortDir = ASC}">Reset sort</button></p>
        </div>

        <table class="uk-table uk-table-hover uk-table-divider">
          <thead>
            <tr class="moves-table__col-names">
              <th @click="setSortMethod('identifier')"
                :class="{
                  asc: getSortMethod('identifier', ASC),
                  desc: getSortMethod('identifier', DESC),
                }">Name</th>
              <th class="uk-text-center"
                @click="setSortMethod('damage_class_id')"
                :class="{
                  asc: getSortMethod('damage_class_id', ASC),
                  desc: getSortMethod('damage_class_id', DESC),
                }">Damage Class</th>

              <th class="uk-text-center"
                @click="setSortMethod('type_id')"
                :class="{
                  asc: getSortMethod('type_id', ASC),
                  desc: getSortMethod('type_id', DESC),
                }">Type</th>

              <th class="uk-text-center"
                @click="setSortMethod('power')"
                :class="{
                  asc: getSortMethod('power', ASC),
                  desc: getSortMethod('power', DESC),
                }">Power</th>

              <th class="uk-text-center"
                @click="setSortMethod('pp')"
                :class="{
                  asc: getSortMethod('pp', ASC),
                  desc: getSortMethod('pp', DESC),
                }">PP</th>

              <th class="uk-text-center"
                @click="setSortMethod('accuracy')"
                :class="{
                  asc: getSortMethod('accuracy', ASC),
                  desc: getSortMethod('accuracy', DESC),
                }">Accuracy</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="idx in moves" v-bind:key="idx" v-on:click="seeMoveDetails(moves_.id[idx])">
              <td class="uk-text-capitalize uk-text-middle">{{ moves_.identifier[idx].split('-').join(' ') }}</td>
              <td class="uk-text-capitalize uk-text-center uk-text-middle">{{ damageClassResolver(moves_.damage_class_id[idx]) }}</td>
              <td class="uk-text-center uk-text-capitalize uk-flex uk-flex-center uk-text-middle">
                <TypeDisplay :typeId="moves_.type_id[idx]" />
              </td>
              <td class="uk-text-center uk-text-middle">{{ numOrDash(moves_.power[idx]) }}</td>
              <td class="uk-text-center uk-text-middle">{{ numOrDash(moves_.pp[idx]) }}</td>
              <td class="uk-text-center uk-text-middle">{{ numOrDash(moves_.accuracy[idx]) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </vk-grid>
  </div>
</template>

<script>
import SearchBox from '../components/SearchBox.vue'
import PokemonSpecies from '../dataStorage/pokemonSpecies'
import PokemonMoves from '../dataStorage/pokemonMoves'
import TypeDisplay from '../components/TypeDisplay.vue'

const ASC = 0
const DESC = 1

export default {
  name: 'Moves',
  components: {
    SearchBox,
    TypeDisplay,
  },
  data: function () {
    return {
      search_param: '',
      moves_: this.$store.state.pokemonMoves,
      typeFilters: [],
      damageClassFilters: [],
      sortBy: 'id',
      sortDir: ASC,
      ASC: ASC,
      DESC: DESC,
    }
  },
  methods: {
    typeNameResolver: PokemonSpecies.typeNameResolver,
    typeColorResolver: PokemonSpecies.typeColorResolver,
    damageClassResolver: PokemonMoves.damageClassResolver,
    numOrDash: function (num) {
      if (Number.isInteger(num))
        return num

      return String.fromCodePoint(8213)
    },
    setSortMethod: function (sortBy) {
      if (this.sortBy === sortBy)
        this.sortDir = Math.abs(this.sortDir - 1)
      else {
        this.sortBy = sortBy
        this.sortDir = DESC
      }
    },
    getSortMethod: function (category, dir) {
      return (category === this.sortBy) && (this.sortDir === dir)
    },
    seeMoveDetails: function (moveId) {
      this.$router.push({ name: 'MoveInfo', params: { moveId: moveId } })
    },
  },
  computed: {
    moves: function () {
      const searchRet = []

      if (this.search_param.length > 0) {
        const searchResults = this.$store.state.movesLanguageModel.search(this.search_param)
        const closestMatchThreshold = 0.01

        if (searchResults.length == 0)
          return []

        let closestMatchFound = searchResults[0].score <= closestMatchThreshold

        for (let i = 0; i < searchResults.length; ++i) {
          const atkIdx = searchResults[i].refIndex
          const nameMatchesSearchParam = this.moves_.identifier[atkIdx].toLowerCase().split('-').join(' ') === this.search_param.toLowerCase()

          if ((closestMatchFound && searchResults[i].score > closestMatchThreshold))
            break

          searchRet.push(atkIdx)

          if (nameMatchesSearchParam)
            break
        }
      } else {
        for (let i = 0; i < this.moves_.id.length; ++i) {
          searchRet.push(i)
        }
      }

      return searchRet.filter(a => {
        let filterPassed = true

        filterPassed &= !(this.typeFilters.length > 0 && this.typeFilters.indexOf(this.moves_.type_id[a]) === -1)
        filterPassed &= !(this.damageClassFilters.length > 0 && this.damageClassFilters.indexOf(this.moves_.damage_class_id[a]) === -1)

        return filterPassed
      }).sort((a, b) => {
        switch (this.sortBy) {
          case 'accuracy':
          case 'power':
          case 'pp':
          case 'id': {
            let valA = this.moves_[this.sortBy][a]
            let valB = this.moves_[this.sortBy][b]

            valA = (Number.isInteger(valA)) ? valA : -1
            valB = (Number.isInteger(valB)) ? valB : -1

            if (this.sortDir === ASC)
              return valA - valB
            return valB - valA
          }

          case 'type_id': {
            let valA = this.moves_[this.sortBy][a]
            let valB = this.moves_[this.sortBy][b]

            valA = (Number.isInteger(valA)) ? valA : 0
            valB = (Number.isInteger(valB)) ? valB : 0

            const typeNameA = this.typeNameResolver(valA)
            const typeNameB = this.typeNameResolver(valB)

            if (this.sortDir === ASC)
              return (typeNameA < typeNameB) ? 1 : -1
            return (typeNameA > typeNameB) ? 1 : -1
          }

          case 'damage_class_id': {
            let valA = this.moves_[this.sortBy][a]
            let valB = this.moves_[this.sortBy][b]

            valA = (Number.isInteger(valA)) ? valA : 'zzz'
            valB = (Number.isInteger(valB)) ? valB : 'zzz'

            const damageClassA = this.damageClassResolver(valA)
            const damageClassB = this.damageClassResolver(valB)

            if (this.sortDir === ASC)
              return (damageClassA < damageClassB) ? 1 : -1
            return (damageClassA > damageClassB) ? 1 : -1
          }

          case 'identifier': {
            let valA = this.moves_[this.sortBy][a]
            let valB = this.moves_[this.sortBy][b]

            if (this.sortDir === ASC)
              return (valA < valB) ? 1 : -1
            return (valA > valB) ? 1 : -1
          }
        }
      })
    },
  },
  activated: function () {
    this.$store.dispatch('closeAppMenu')
  },
}
</script>

<style scoped>
.moves-filter__input-block.moves-filter__input-block__attack-type,
.attack-type {
  padding: 2px;
  color: #ffffff;
  text-shadow: 0 1px 0 #000,0 0 1px rgba(0,0,0,.6),0 0 2px rgba(0,0,0,.3),0 0 3px rgba(0,0,0,.6),0 0 4px rgba(0,0,0,.3);
  -webkit-font-smoothing: antialiased;
  padding: 5px 10px;
  width: 60px;
  font-weight: bold;
}

.moves-filter__input-block.moves-filter__input-block__attack-type,
.moves-filter__input-block {
  padding: 5px;
  display: inline-block;
  width: 95px;
  margin: 5px;
}

.moves-table__col-names > th {
  cursor: pointer;
}

.moves-table__col-names > th:after {
  display: inline-block;
  content: ' ';
  position: absolute;
  padding: 0 2px;
}

.moves-table__col-names > th:not(.asc):not(.desc):hover:after {
  content: '\2191\2193';
}

.moves-table__col-names > th.asc:hover:after {
  content: '\2191';
}

.moves-table__col-names > th.desc:hover:after {
  content: '\2193';
}
</style>
