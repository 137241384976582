<template>
  <div class="variant-moves">
    <PropertyTitle :value="'Moves'" />

    <select class="uk-select uk-width-1-2@m" v-model="selectedGen">
      <option v-for="(value, key) in generations"
        :value="key"
        v-bind:key="key">{{ value.title }}</option>
    </select>

    <div uk-alert v-if="movesFiltered.length == 0">
      <p>No move data is available for this game.</p>
    </div>

    <p v-if="movesFiltered.length != 0">Note: Moves in <b>bold</b> receive same-type attack bonus (STAB)</p>
    <vk-grid class="uk-child-width-1-1 uk-child-width-1-3@m" matched>
      <div v-if="movesLevelUp.length > 0" class="uk-width-1-2@m">
        <div>
          <h3>Level-up Move</h3>
          <table class="uk-table uk-table-divider uk-table-striped uk-table-hover">
            <thead>
                <tr>
                  <th style="width: 30px">Level</th>
                  <th>Attack</th>
                  <th>Type</th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="move in movesLevelUp" v-bind:key="move.move.name" v-on:click="seeMoveDetails( getMoveIdFromUrl(move.move.url) )" :class="{'variant-moves__stab-move':isStabMove(move)}">
                <td><b>{{ move.version_details[selectedGen]['level-up'].level_learned_at }}</b></td>
                <td>{{ getMoveName(move) }}</td>
                <td>
                  <TypeDisplay :typeId="getMoveType(move)" :renderSmall="true" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="eggMove.length > 0">
        <div>
          <h3>Egg Move</h3>
          <ListRenderer :data="eggMove" :nameGetter="getMoveName" />
        </div>
      </div>

      <div v-if="tutorMove.length > 0">
        <div>
          <h3>Move Tutor</h3>
          <ListRenderer :data="tutorMove" :nameGetter="getMoveName" />
        </div>
      </div>

      <div v-if="machineMove.length > 0">
        <div>
          <h3>TM/HM Move</h3>
          <ListRenderer :data="machineMove" :nameGetter="getMoveName" />
        </div>
      </div>
    </vk-grid>
  </div>
</template>

<script>
import PropertyTitle from './PropertyTitle.vue'
import ListRenderer from './ListRenderer.vue'
import TypeDisplay from '../TypeDisplay.vue'

export default {
  name: 'PokeAPIVariantMoves',
  components: {
    PropertyTitle,
    ListRenderer,
    TypeDisplay,
  },
  props: {
    moves: Array,
    pokemonType: Array,
  },
  data: function () {
    const generations = this.$store.state.generations
    const generationKeys = Object.keys(generations)

    return {
      generations,
      selectedGen: generationKeys[generationKeys.length - 3],
      moves_: this.moves.map(m => {
        m['version_details'] = {}

        for (let version in m.version_group_details) {
          if (m['version_details'][m.version_group_details[version].version_group.name] === undefined)
            m['version_details'][m.version_group_details[version].version_group.name] = {}

          m['version_details'][m.version_group_details[version].version_group.name][m.version_group_details[version].move_learn_method.name] = m.version_group_details[version]
        }

        return m
      }),
    }
  },
  methods: {
    moveLearnByFilter: function (move, filter) {
      for (let i in move.version_group_details) {
        const moveInGen = move.version_group_details[i].version_group.name == this.selectedGen
        const moveLearnBy = move.version_group_details[i].move_learn_method.name == filter
        if (moveInGen && moveLearnBy)
          return true
      }

      return false
    },
    getMoveName: function (move) {
      return move.move.name.split('-').map(token => {
        return token.charAt(0).toUpperCase() + token.slice(1)
      }).join(' ')
    },
    getMoveType: function (move) {
      const moveIndex = this.$store.state.pokemonMoves.identifier.indexOf(move.move.name)
      return this.$store.state.pokemonMoves.type_id[moveIndex]
    },
    moveSortByName: function (a, b) {
      if(this.getMoveName(a) < this.getMoveName(b)) { return -1 }
      if(this.getMoveName(a) > this.getMoveName(b)) { return 1 }

      return 0
    },
    getMoveIdFromUrl : function (moveUrl) {
      return moveUrl.split('/').filter((el, idx, arr) => {
        return !(idx == arr.length - 1 && el == '')
      }).slice(-1)[0]
    },
    seeMoveDetails: function (moveId) {
      moveId = Number.parseInt(moveId)
      moveId = Number.isNaN(moveId) ? 0 : moveId

      this.$router.push({ name: 'MoveInfo', params: { moveId: moveId } })
    },
    isStabMove: function (move) {
      const moveType = this.getMoveType(move)
      console.log(this.pokemonType)
      return (this.pokemonType.indexOf(moveType) !== -1)
    }
  },
  computed: {
    movesFiltered: function () {
      return this.moves_.filter(m => {
        return Object.keys(m.version_details).indexOf(this.selectedGen) != -1
      })
    },
    movesLevelUp: function () {
      return this.movesFiltered.filter(move => {
        return this.moveLearnByFilter(move, 'level-up')
      }).sort((a, b) => {
        const levelA = Number.parseInt(a['version_details'][this.selectedGen]['level-up'].level_learned_at)
        const levelB = Number.parseInt(b['version_details'][this.selectedGen]['level-up'].level_learned_at)

        return levelA - levelB
      })
    },
    eggMove: function () {
      return this.movesFiltered.filter(move => {
        return this.moveLearnByFilter(move, 'egg')
      }).sort(this.moveSortByName)
    },
    tutorMove: function () {
      return this.movesFiltered.filter(move => {
        return this.moveLearnByFilter(move, 'tutor')
      }).sort(this.moveSortByName)
    },
    machineMove: function () {
      return this.movesFiltered.filter(move => {
        return this.moveLearnByFilter(move, 'machine')
      }).sort(this.moveSortByName)
    },
  }
}
</script>

<style scoped>
.variant-moves__stab-move {
  font-weight: bold;
}
</style>
