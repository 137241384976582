import Vue from 'vue'
import VueRouter from 'vue-router'
import Dex from '../views/Dex.vue'
import DexEntry from '../views/DexEntry.vue'
import TypeCalc from '../views/TypeCalc.vue'
import Moves from '../views/Moves.vue'
import MovesInfo from '../views/MovesInfo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dex'
  },
  {
    path: '/dex',
    name: 'Dex',
    component: Dex
  },
  {
    path: '/dex/:number',
    name: 'DexEntry',
    component: DexEntry
  },
  {
    path: '/typeCalculator/:tab?/:slug?',
    name: 'TypeCalc',
    component: TypeCalc
  },
  {
    path: '/move',
    name: 'Move',
    component: Moves
  },
  {
    path: '/move/:moveId',
    name: 'MoveInfo',
    component: MovesInfo
  },
  {
    path: '*',
    redirect: '/dex'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
