<template>
  <div class="dex__search uk-flex uk-flex-center uk-margin">
    <div class="uk-margin">
      <div class="uk-flex uk-flex-center uk-flex-middle">
        <div>
          <vk-icon icon="search"></vk-icon>
        </div>
        <div>
          <input class="uk-form-large dex__search__box" type="search" placeholder="Search" v-model="search_param">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchBox',
  props: {
    onValueChange: Function,
  },
  data: function () {
    return {
      search_param: ''
    }
  },
  watch: {
    search_param: function () {
      if (typeof this.onValueChange === 'function')
        this.onValueChange(this.search_param)
    },
  },
}
</script>

<style scoped>
.dex__search__box {
  border: none;
  border-bottom: solid 1px rgba(0,0,0,0.5);
}
</style>
