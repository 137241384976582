import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './registerServiceWorker'
import VueLazyload from 'vue-lazyload'

// Import UIKit
import Vuikit from 'vuikit'
import VuikitIcons from '@vuikit/icons'
import '@vuikit/theme'
import router from './router'

Vue.config.productionTip = false
Vue.use(Vuikit)
Vue.use(VuikitIcons)

Vue.use(VueLazyload)
Vue.use(router)

new Vue({
  store: store.store,
  render: h => h(App),
  router,

  computed: {
    ...store.mapState(['loading']),
    ...store.mapState(['initialLoading']),
    ...store.mapState(['appFunctionMenuOpened']),
      //...mapState(['users']),
      /*users () { // same as mapState
      	return this.$store.state.users;
      }*/
      /*users() { // also possible with mapGetters(['users'])
        return this.$store.getters.users
      }*/
  }
}).$mount('#app')
