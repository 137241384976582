<template>
  <div class="skeleton-loader__container">
    <div class="o-media__body">
      <div class="o-vertical-spacing">
        <h3 class="blog-post__headline">
          <span class="skeleton-box" style="width:55%;"></span>
        </h3>
        <p>
          <span class="skeleton-box" style="width:80%;"></span>
          <span class="skeleton-box" style="width:90%;"></span>
          <span class="skeleton-box" style="width:83%;"></span>
          <span class="skeleton-box" style="width:80%;"></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonLoader',
}
</script>

<style scoped>
.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
  text-align: left;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 2s infinite;
          animation: shimmer 2s infinite;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.o-media {
  display: flex;
}
.o-media__body {
  flex-grow: 1;
}

.skeleton-loader__container * {
  box-sizing: border-box;
  text-align: left;
}
</style>
