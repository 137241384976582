import { render, staticRenderFns } from "./variantAbilities.vue?vue&type=template&id=036974de&scoped=true&"
import script from "./variantAbilities.vue?vue&type=script&lang=js&"
export * from "./variantAbilities.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "036974de",
  null
  
)

export default component.exports