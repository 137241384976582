<template>
  <div id="app">
    <FullpageLoading v-if="initialLoading" />
    <AppFunctionMenu />

    <div v-if="!initialLoading">
      <vk-sticky>
        <vk-navbar class="uk-margin">
          <vk-navbar-toggle title="Menu" @click="openAppMenu()"></vk-navbar-toggle>
        </vk-navbar>
      </vk-sticky>

      <keep-alive>
        <router-view :scroll_last_position="scroll_container" :key="$route.path" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import FullpageLoading from './components/FullpageLoading.vue'
import AppFunctionMenu from './components/AppFunctionMenu.vue'

export default {
  name: 'App',
  components: {
    FullpageLoading,
    AppFunctionMenu,
  },
  data: function () {
    return {
      scroll: 0,
      scroll_positions: {},
    }
  },
  computed: {
    initialLoading () {
      return this.$store.state.initialLoading
    },
  },
  methods: {
    openAppMenu: function () {
      this.$store.dispatch('openAppMenu')
    },
    scroll_container: function () {
      try {
        this.$refs.scrolled.scrollTop = this.scroll
      } catch (e) {
        return
      }
    }
  },
  created: function () {
    let that = this
    this.$router.afterEach(function (to, from) {
        if (from.name == null || to.name == null)
          return

        try {
          that.scroll_positions[from.name] = that.$refs.scrolled.scrollTop
        } catch (e) {
          that.scroll_positions[from.name] = 0
        }

        let old_pos = that.scroll_positions[to.name]
        that.scroll = old_pos ? old_pos : 0
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
