const types = {
  NORMAL   : 1,
  FIGHTING : 2,
  FLYING   : 3,
  POISON   : 4,
  GROUND   : 5,
  ROCK     : 6,
  BUG      : 7,
  GHOST    : 8,
  STEEL    : 9,
  FIRE     : 10,
  WATER    : 11,
  GRASS    : 12,
  ELECTRIC : 13,
  PSYCHIC  : 14,
  ICE      : 15,
  DRAGON   : 16,
  DARK     : 17,
  FAIRY    : 18,
}

const DamageMatrix = [
// None  Norm  Fight Fly   Pois  Grnd  Rock  Bug   Ghost Steel Fire  Water Grass Elec  Psy   Ice   Drag  Dark  Fairy <- Attacking
  [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00,], // NONE
  [0.00, 1.00, 1.00, 1.00, 1.00, 1.00, 0.50, 1.00, 0.00, 0.50, 1.00, 1.00, 1.00, 1.00, 1.00, 1.00, 1.00, 1.00, 1.00,], // NORMAL
  [0.00, 2.00, 1.00, 0.50, 0.50, 1.00, 2.00, 0.50, 0.00, 2.00, 1.00, 1.00, 1.00, 1.00, 0.50, 2.00, 1.00, 2.00, 0.50,], // FIGHTING
  [0.00, 1.00, 2.00, 1.00, 1.00, 1.00, 0.50, 2.00, 1.00, 0.50, 1.00, 1.00, 2.00, 0.50, 1.00, 1.00, 1.00, 1.00, 1.00,], // FLYING
  [0.00, 1.00, 1.00, 1.00, 0.50, 0.50, 0.50, 1.00, 0.50, 0.00, 1.00, 1.00, 2.00, 1.00, 1.00, 1.00, 1.00, 1.00, 2.00,], // POISON
  [0.00, 1.00, 1.00, 0.00, 2.00, 1.00, 2.00, 0.50, 1.00, 2.00, 2.00, 1.00, 0.50, 2.00, 1.00, 1.00, 1.00, 1.00, 1.00,], // GROUND
  [0.00, 1.00, 0.50, 2.00, 1.00, 0.50, 1.00, 2.00, 1.00, 0.50, 2.00, 1.00, 1.00, 1.00, 1.00, 2.00, 1.00, 1.00, 1.00,], // ROCK
  [0.00, 1.00, 0.50, 0.50, 0.50, 1.00, 1.00, 1.00, 0.50, 0.50, 0.50, 1.00, 2.00, 1.00, 2.00, 1.00, 1.00, 2.00, 0.50,], // BUG
  [0.00, 0.00, 1.00, 1.00, 1.00, 1.00, 1.00, 1.00, 2.00, 1.00, 1.00, 1.00, 1.00, 1.00, 2.00, 1.00, 1.00, 0.50, 1.00,], // GHOST
  [0.00, 1.00, 1.00, 1.00, 1.00, 1.00, 2.00, 1.00, 1.00, 0.50, 0.50, 0.50, 1.00, 0.50, 1.00, 2.00, 1.00, 1.00, 2.00,], // STEEL
  [0.00, 1.00, 1.00, 1.00, 1.00, 1.00, 0.50, 2.00, 1.00, 2.00, 0.50, 0.50, 2.00, 1.00, 1.00, 2.00, 0.50, 1.00, 1.00,], // FIRE
  [0.00, 1.00, 1.00, 1.00, 1.00, 2.00, 2.00, 1.00, 1.00, 1.00, 2.00, 0.50, 0.50, 1.00, 1.00, 1.00, 0.50, 1.00, 1.00,], // WATER
  [0.00, 1.00, 1.00, 0.50, 0.50, 2.00, 2.00, 0.50, 1.00, 0.50, 0.50, 2.00, 0.50, 1.00, 1.00, 1.00, 0.50, 1.00, 1.00,], // GRASS
  [0.00, 1.00, 1.00, 2.00, 1.00, 0.00, 1.00, 1.00, 1.00, 1.00, 1.00, 2.00, 0.50, 0.50, 1.00, 1.00, 0.50, 1.00, 1.00,], // ELECTRIC
  [0.00, 1.00, 2.00, 1.00, 2.00, 1.00, 1.00, 1.00, 1.00, 0.50, 1.00, 1.00, 1.00, 1.00, 0.50, 1.00, 1.00, 0.00, 1.00,], // PSYCHIC
  [0.00, 1.00, 1.00, 2.00, 1.00, 2.00, 1.00, 1.00, 1.00, 0.50, 0.50, 0.50, 2.00, 1.00, 1.00, 0.50, 2.00, 1.00, 1.00,], // ICE
  [0.00, 1.00, 1.00, 1.00, 1.00, 1.00, 1.00, 1.00, 1.00, 0.50, 1.00, 1.00, 1.00, 1.00, 1.00, 1.00, 2.00, 1.00, 0.00,], // DRAGON
  [0.00, 1.00, 0.50, 1.00, 1.00, 1.00, 1.00, 1.00, 2.00, 1.00, 1.00, 1.00, 1.00, 1.00, 2.00, 1.00, 1.00, 0.50, 0.50,], // DARK
  [0.00, 1.00, 2.00, 1.00, 0.50, 1.00, 1.00, 1.00, 1.00, 0.50, 0.50, 1.00, 1.00, 1.00, 1.00, 1.00, 2.00, 2.00, 1.00,], // FAIRY
                                                                                                                       // ^ Defending
]

DamageMatrix.types = types

export default DamageMatrix
