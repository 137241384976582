<template>
  <div class="pokemonGrid">
    <vk-grid class="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-6@m" v-if="search_param.length >= 3">
      <PokemonGridChild
        v-for="index in pokemonSpeciesFiltered.identifier.length"
        v-bind:key="index"
        :speciesName="pokemonSpeciesFiltered.identifier[index - 1]"
        :speciesNumber="pokemonSpeciesFiltered.id != null ? pokemonSpeciesFiltered.id[index - 1] : index"
      />
    </vk-grid>

    <keep-alive>
      <vk-grid class="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-6@m" v-if="search_param.length < 3">
        <PokemonGridChild
          v-for="(speciesName, speciesIndex) in pokemonSpecies.identifier"
          v-bind:key="speciesName"
          :speciesName="speciesName"
          :speciesNumber="speciesIndex + 1"
        />
      </vk-grid>
    </keep-alive>
  </div>
</template>

<script>
import PokemonGridChild from './PokemonGridChild.vue'

export default {
  name: 'PokemonGrid',
  computed: {
    pokemonSpecies () {
      return this.$store.state.pokemonSpecies
    },
    pokemonSpeciesFiltered: function () {
      if (this.search_param.length < 3) {
        const speciesList = this.pokemonSpecies
        speciesList.id = null

        return speciesList
      }

      const searchResults = this.$store.state.languageModel.search(this.search_param)
      const returnResult = {id: [], identifier: []}

      const closestMatchThreshold = 0.01
      let closestMatchFound = searchResults[0].score <= closestMatchThreshold

      for (let i in searchResults) {
        if (closestMatchFound && searchResults[i].score > closestMatchThreshold)
          break

        returnResult.id.push(searchResults[i].refIndex + 1)
        returnResult.identifier.push(searchResults[i].item)
      }

      return returnResult
    },
  },
  components: {
    PokemonGridChild,
  },
  props: {
    search_param: String
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
