<template>
  <vk-grid class="uk-grid-match uk-grid-small uk-flex-center uk-flex-left@s uk-padding@s">
    <div :class="typeSelectorClass" v-for="type in typeNames" v-bind:key="type.name" :data-disabled="type.value == disabledType">
      <div :class="inputContainerClass" :style="{'background-color':type.color}">
        <label :class="secondaryTypeSelectorClass"><input class="uk-radio" type="radio" :name="name" v-model="selectedType" :value="type.value" :disabled="type.value == disabledType">{{ type.name }}</label>
      </div>
    </div>

    <div :class="typeSelectorClass" class="type-calc__type-selector__input__deselect" v-if="showNoneType">
      <div :class="inputContainerClass">
        <label :class="secondaryTypeSelectorClass"><input class="uk-radio" type="radio" :name="name" v-model="selectedType" value="0">None</label>
      </div>
    </div>
  </vk-grid>
</template>

<script>
import DamageMatrix from '../dataStorage/typeDamageMatrix'
import PokemonSpecies from '../dataStorage/pokemonSpecies'

export default {
  name: 'AttackEfficacy',
  props: {
    onChangeType: Function,
    showNoneType: Boolean,
    disabledType: Number,
    name: String,
    activeType: Number,
  },
  data: function () {
    return {
      types: DamageMatrix.types,
      typeSelectorClass: 'type-calc__type-selector',
      primaryTypeSelectorClass: 'uk-text-capitalize type-calc__type-selector__primaryType',
      secondaryTypeSelectorClass: 'uk-text-capitalize type-calc__type-selector__secondaryType',
      inputContainerClass: 'type-calc__type-selector__input',
      selectedType: this.activeType,
    }
  },
  computed: {
    typeNames: function () {
      return Object.keys(this.types).map(typeKey => {
        return {
          name: PokemonSpecies.typeNameResolver(this.types[typeKey]),
          value: this.types[typeKey],
          color: PokemonSpecies.typeColorResolver(this.types[typeKey]),
        }
      }).sort((a, b) => {
        if (a.name >= b.name)
          return 1
        return -1
      })
    },
  },
  watch: {
    selectedType: function (newVal, oldVal) {
      if (newVal == oldVal || newVal == this.activeType)
        return

      this.onChangeType(newVal)
    },
    activeType: function (newVal, oldVal) {
      if (newVal == oldVal)
        return

      this.selectedType = newVal
    },
  },
}
</script>

<style scoped>
.type-calc__type-selector {
  width: 120px;
}

.type-calc__type-selector__input {
  color: #ffffff;
  text-shadow: 0 1px 0 #000,0 0 1px rgba(0,0,0,.6),0 0 2px rgba(0,0,0,.3),0 0 3px rgba(0,0,0,.6),0 0 4px rgba(0,0,0,.3);
  -webkit-font-smoothing: antialiased;
  padding: 5px 10px;
  width: 100px;
  font-weight: bold;
}

.type-calc__type-selector__input input {
  margin-right: 10px;
}

.type-calc__type-selector__input__deselect .type-calc__type-selector__input {
  border: solid 1px #000;
}

.type-calc__type-selector[data-disabled] {
  opacity: 0.5;
}

.uk-radio:not(:disabled):not(:checked) {
  background: #fff;
}

.type-calc__type-selector__input__deselect > * {
  color: #000;
  text-shadow: none;
}
</style>
