<template>
  <div class="variant-sprite">
    <PropertyTitle :value="'Sprites'" v-if="recursive !== true" />

    <div class="uk-container" v-if="spritesFiltered.length >= 1">
      <div>
        <vk-tabs align="justify" :keep-alive="true">
          <vk-tabs-item v-for="sprite in spritesFiltered" v-bind:key="sprite" :title="sprite.split('_').join(' ')">
            <div v-if="typeof(sprites[sprite]) === 'string'" class="uk-container uk-text-center uk-flex uk-flex-center">
              <vk-card class="uk-width-medium">
                <p><img :src="sprites[sprite]"></p>
              </vk-card>
            </div>
            <PokeAPISprite v-if="typeof(sprites[sprite]) === 'object'" :sprites="sprites[sprite]" :name="sprite" :recursive="true" />
          </vk-tabs-item>
        </vk-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import PropertyTitle from './PropertyTitle.vue'

export default {
  name: 'PokeAPISprite',
  components: {
    PropertyTitle,
  },
  props: {
    sprites: Object,
    recursive: Boolean,
  },
  data: function () {
    return {
      showModal: false,
      requestedAbility: null,
    }
  },
  methods: {
    showAbilityModal: function (ability) {
      this.requestedAbility = ability
      this.showModal = true
    },
    updateModalState: function (newState) {
      this.showModal = newState
    },
    spritesFilterHelper: function (sprites) {
    const keys = sprites ? Object.keys(sprites) : undefined
    if (keys === undefined)
      return false

    return keys.filter(key => {
      if (typeof sprites[key] === 'string')
        return true
      else
        return this.spritesFilterHelper(sprites[key])
    }).length >= 1
    }
  },
  computed: {
    spritesFiltered: function () {
      const keys = Object.keys(this.sprites)
      if (keys === undefined)
        return []

      return keys.filter(key => {
        return this.spritesFilterHelper(this.sprites[key])
      })
    },
  },
}
</script>

<style scoped>
</style>
