<template>
  <h2>{{ value }}</h2>
</template>

<script>
export default {
  name: 'PokeAPIPropertyTitle',
  props: {
    value: String
  }
}
</script>

<style scoped>

</style>
