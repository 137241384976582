<template>
  <div>
    <ul class="uk-list uk-list-large">
      <li v-for="(d, i) in data" v-bind:key="i">{{ nameGetter(d) }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PokeAPIListRenderer',
  props: {
    data: Array,
    nameGetter: Function,
  }
}
</script>

<style scoped>

</style>
