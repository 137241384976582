<template>
  <img v-lazy="spriteUrl" width="96px" height="96px">
</template>

<script>
export default {
  name: 'PokemonSpriteComponent',
  props: {
    speciesNumber: Number,
  },
  computed: {
    spriteUrl () {
      const basePath = 'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/'
      const fileFormat = '.png'
      return `${basePath}${this.speciesNumber}${fileFormat}`
    }
  },
}
</script>
